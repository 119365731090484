<template>
  <!-- begin:: Footer -->
  <div class="footer py-4 d-flex flex-lg-column mb-20" id="kt_footer">
    <!--begin::Container-->
    <div
      class="d-flex flex-column flex-md-row align-items-center justify-content-between"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <!--begin::Copyright-->
      <div class="text-dark order-2 order-md-1">
        <span class="text-muted font-weight-bold mr-2">2022©</span>
        <a href="http://donebyexperts.com" target="_blank" class="text-dark-75 text-hover-primary"
          >...andlastly.io | donebyEXPERTS</a
        >
      </div>
      <!--end::Copyright-->
      <!--begin::Nav-->
      <div class="nav nav-dark order-1 order-md-2">
        <a href="http://andlastly.io" target="_blank" class="nav-link pr-3 pl-0">About</a>

        <a href="https://andlastly.io/privacy/" target="_blank" class="nav-link pl-3 pr-0">Privacy Policy</a>
        <a class="nav-link pl-3" href="#">v{{ appVersion }}</a>
      </div>
      <!--end::Nav-->
    </div>
    <!--end::Container-->
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'KTFooter',

  computed: {
    ...mapGetters(['appVersion', 'layoutConfig', 'clientId']),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig('footer.width') === 'fluid';
    }
  }
};
</script>
