<template>
  <div class="topbar-item mr-4">
    <div id="kt_quick_notifications_toggle">
      <div class="btn btn-icon btn-sm font-weight-bolder p-0" :class="unread == 0 ? 'btn-default' : 'btn-light-danger'">
        {{ unreadNotifications.length }}
      </div>
    </div>

    <!-- begin::Notifications Panel-->
    <div id="kt_quick_notifications" ref="kt_quick_notifications" class="offcanvas offcanvas-right p-10">
      <div class="offcanvas-header d-flex align-items-center justify-content-between mb-10">
        <h3 class="font-weight-bold m-0">
          Notifications
          <small class="text-muted font-size-sm ml-2">{{ notifications.length }} Notifications</small>
        </h3>
        <a href="#" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_notifications_close">
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--begin::Content-->
      <div class="offcanvas-content pr-5 mr-n5">
        <div class="navi navi-icon-circle navi-spacer-x-0">
          <template v-for="(item, i) in notifications">
            <!--begin::Item-->
            <a
              href="#"
              v-b-tooltip.hover
              :title="item.status == 0 ? 'Mark as Read' : 'Mark as Unread'"
              @click="toggle(item)"
              class="navi-item"
              v-bind:key="i"
            >
              <div class="navi-link rounded">
                <div class="symbol symbol-50 mr-3 ">
                  <div class="symbol-label">
                    <label
                      for=""
                      :class="apiBusy && currentItem.id == item.id ? 'spinner spinner-success' : ''"
                      class="checkbox checkbox-outline checkbox-success"
                    >
                      <input type="checkbox" :id="'checkbox' + i" v-model="item.status" />
                      <span></span>
                    </label>
                  </div>
                </div>
                <div class="navi-text">
                  <div class="font-size-lg" :class="item.status == 0 ? 'font-weight-bolder ' : ''">
                    {{ getContent(item.content, 55) }}
                  </div>
                  <span class="text-muted">
                    {{ getContentMeta(item) }}
                  </span>
                </div>
              </div>
            </a>
            <!--end::Item-->
          </template>
        </div>
      </div>
      <!--end::Content-->
    </div>
    <!-- end::Notifications Panel-->
  </div>
</template>

<script>
import KTLayoutQuickNotifications from "@/assets/js/layout/extended/quick-notifications.js";
import { GET_NOTIFICATIONS, TOGGLE_NOTIFICATION } from "@/core/services/store/notifications.module";
import { mapActions, mapGetters } from "vuex";
import Helpers from "@/core/services/helpers.service.js";

export default {
  name: "KTQuickPanel",

  data() {
    return {
      unread: 0,
      currentItem: 0
    };
  },

  computed: {
    ...mapGetters(["notifications", "unreadNotifications", "apiBusy", "isAuthenticated"])
  },

  mounted() {
    // Init Quick Offcanvas Panel
    KTLayoutQuickNotifications.init(this.$refs["kt_quick_notifications"]);
  },

  beforeMount() {
    if (this.isAuthenticated) {
      if (this.notifications || this.notifications.length < 1) {
        this.$store
          .dispatch(GET_NOTIFICATIONS)

          .catch(() => {
            this.toast({
              variant: "warning",
              content: "Error | Unable to load notifications.  Will try again shortly"
            });
          })

          .finally(() => {
            this.currentItem = 0;
          });
      }
    }
  },

  methods: {
    ...mapActions(["toast"]),

    getIcon(object) {
      var icon = "";
      switch (object) {
        case "trustees":
          icon = "/media/svg/icons/Code/Git2.svg";
          break;

        default:
          icon = "/media/svg/icons/General/Notifications1.svg";
          break;
      }
      return icon;
    },

    getContent(text, maxLength) {
      return Helpers.shorten(text, maxLength);
    },

    getContentMeta(item) {
      const status = item.status != 0 ? "read" : "unread";
      return this.timeSince(item.created_at) + " | " + status;
    },

    timeSince(date) {
      return Helpers.timeSince(date);
    },

    toggle(notification) {
      this.currentItem = notification;

      this.$store
        .dispatch(TOGGLE_NOTIFICATION, notification.id)

        .catch(error => {
          this.toast("Error | " + error.message);
        });
    }
  }
};
</script>
