<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar">
    <!-- create message -->
    <div class="topbar-item">
      <router-link :to="{ name: 'messages.create' }" class="btn font-weight-bolder btn-sm btn-secondary px-5 mr-5">
        Create Message
      </router-link>
    </div>

    <!-- begin:: trustees icon -->
    <div class="topbar-item mr-4">
      <router-link
        v-b-tooltip.hover
        title="Trustees"
        :to="{ name: 'trustees' }"
        :class="trusteesActive"
        class="btn btn-icon btn-sm btn-clean btn-text-dark-75 "
        id="kt_quick_panel_toggle"
      >
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Code/Git2.svg" />
        </span>
      </router-link>
    </div>
    <!-- begin:: trustees icon -->

    <!-- begin:: messages icon  -->
    <div class="topbar-item mr-4">
      <router-link
        v-b-tooltip.hover
        title="Messages"
        :class="messagesActive"
        :to="{ name: 'messages' }"
        class="btn btn-icon btn-sm btn-clean btn-text-dark-75"
        id="kt_quick_panel_toggle"
      >
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Communication/Mail.svg" />
        </span>
      </router-link>
    </div>
    <!--  @todo:  will work on notifications later -->
    <KTQuickNotifications></KTQuickNotifications>

    <!--begin: User Bar -->
    <KTQuickUser></KTQuickUser>
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
import KTQuickNotifications from "@/view/layout/extras/offcanvas/QuickNotifications.vue";

export default {
  name: "KTTopbar",

  data() {
    return {};
  },

  components: {
    KTQuickUser,
    KTQuickNotifications
  },

  methods: {},

  computed: {
    getLanguageFlag() {
      return this.onLanguageChanged();
    },
    messagesActive() {
      return this.$route.name.includes("messages") ? "active" : "";
    },
    trusteesActive() {
      return this.$route.name.includes("trustees") ? "active" : "";
    }
  }
};
</script>
